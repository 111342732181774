.payment-container {
    height: 100vh;
    width: 100%;
    background: var(--pallate-blue-gray-900, #0F172A);
    display: flex;
    overflow: hidden;
}

.payment-info{
    display: flex;
    flex-direction: column;
    gap:10px;
    flex-grow: 1px;
    padding: 100px;
}