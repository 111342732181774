.forgotPasssword{
    height: 100vh;
    width: 100%;
    background: #1E293B;
    display: flex;
    flex-direction: row;
    align-items: center;
}
body::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
.Rectangleframe-forgotpassword{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content:center ;
    align-items:center ;
    flex-shrink: 0;
    background: linear-gradient(155deg, #00C2FF 0.53%, #9746FF 88.22%);
}

@media screen and (max-width: 900px)and (min-width:600px){
    .forgotPasssword{
        height: auto;
        width: 100%;
        background: #1E293B;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .Rectangleframe-forgotpassword{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content:center ;
        align-items:center ;
        height: 100vh;
        flex-shrink: 0;
        background: linear-gradient(155deg, #00C2FF 0.53%, #9746FF 88.22%);
    }
  }