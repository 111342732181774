.checkout{
    width: 100%;
    gap: 40px;
    align-self: stretch;
}

.save-card{
    display: flex;
    align-items: center;
    gap: 8px;
}
.card-details{
    padding: 24px 0px 0px 0px;
}
.checkout-buttons{
display: flex;
justify-content: space-between;
}
.check-box{
    width: 16px;
    height: 16px;
    border: 2px solid var(--pallate-gray-300, #D1D5DB);
    background: var(--light-surfaces-content, #FFF);
}

.payment_loading{
    display: flex;
    height: 162px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    flex-shrink: 1;
    align-self: stretch;
    margin-top: 80%;
}


.pay_load_in{
    display: flex;
flex-direction: column;
align-items: center;
gap: 16px;
align-self: stretch;
}

.pay_load_text{
    display: flex;
flex-direction: column;
align-items: center;
gap: 12px;
align-self: stretch;
}
@media screen and (max-width:600px){
    .payment_loading{
        display: flex;
        height: 162px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 24px;
        flex-shrink: 1;
        align-self: stretch;
        margin-top: 80%;
        padding: 0px 24px;
    }
}