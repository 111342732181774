body::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

.loginContainer {
    overflow: auto;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;
    align-items: center;
	background: var(--pallate-blue-gray-800, #1E293B);
}	
.loginContainer::-webkit-scrollbar{
    display: none;
}

.RectangleFrame{
    overflow-y: auto;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content:center ;
    align-items:center ;
    height: 100vh;
    flex-shrink: 0;
    background: linear-gradient(155deg, #00C2FF 0.53%, #9746FF 88.22%);
}
.RectangleFrame::-webkit-scrollbar{
    display: none;
}

.loginFrame{
    width:100%;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 0px 80px 0px rgba(0, 0, 0, 0.10);
}

.loginContents{
    display: flex;
    max-width: 603px;
    padding: 32px;
    flex-direction: column;
    align-items: center;
    gap: 56px;
    background: var(--pallate-blue-gray-700, #334155);
}
.checkBox{
    width: 16px;
height: 16px;
border: 2px solid var(--pallate-gray-300, #D1D5DB);
background: var(--light-surfaces-content, #FFF);
}
.checkBox:hover{
cursor: pointer;
}

@media screen and (max-width: 900px)and (min-width:600px){
    .loginContainer {
        width: 100vw;
        height: 1080px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: var(--pallate-blue-gray-800, #1E293B);
    }
    .RectangleFrame{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content:center ;
        align-items:center ;
        height: 100vh;
        flex-shrink: 0;
        background: linear-gradient(155deg, #00C2FF 0.53%, #9746FF 88.22%);
    }
    .loginFrame{
        margin: 20px;
        width:100%;
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        box-shadow: 0px 0px 80px 0px rgba(0, 0, 0, 0.10);
    }
  }